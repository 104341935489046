// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from '../../../../../menu-items';
import menuSidebar from '../../../../../Menusidebar';
import React, { useMemo } from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const userRole = localStorage.getItem("role");

  const navGroups = useMemo(() => {
    if (userRole === 'superadmin') {
      return menuSidebar.items.map((item) => (
        <NavGroup key={item.id} item={item} />
      ));
    } else {
      return menuItem.items.map((item) => (
        <NavGroup key={item.id} item={item} />
      ));
    }
  }, [userRole]);

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default React.memo(Navigation);
