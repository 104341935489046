// project import

import dashboard from './dashboard';
// import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuSidebar = {
  items: [dashboard]
};

export default menuSidebar;
